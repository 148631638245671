import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ConferenceTable from './ConferenceTable';
import ConferenceModal from './form/ConferenceModal';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import Select from 'react-select'

const options =
    [
        { value: '1', label: 'EV1' },
        { value: '2', label: 'EV2' },
        { value: '3', label: 'EV3' },
    ];

export class Conference extends Component {

    state = {
        events: [],
        isLoggedIn: false,
        listType: 'Conference',
        year: ''
    };

    componentDidMount() {
        this.logPageHit();

        this.getEvents("");
    }

    logPageHit() {
        var pagename = 'Conference';
        var user = JSON.parse(localStorage.getItem('user'));
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        if (user != null) {
            this.setState({ isLoggedIn: true });
        }
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    handleTeamChange = opt => {
        var thisChoice;
        if (opt == null)
            thisChoice = "";
        else
            thisChoice = opt.value;
        this.getEvents(thisChoice);
    }

    getEvents = (team) => {
        var params = this.props.location.search;
        var thisyear = "";
        if (params.includes("?year=")) {
            thisyear = params.replace("?year=", "");
        }
        this.setState({ year: thisyear })
        var url = 'api/Events?type=' + this.state.listType + '&year=' + thisyear + '&team=' + team;
        fetch(url)
            .then(res => res.json())
            .then(res => this.setState({ events: res }))
            .catch(err => console.log(err));
    }

    addEvent = event => {
        this.updateState(event.id);
    }

    updateState = (id) => {
        //console.log("updating state... ", id);
        this.getEvents("");
    }

    deleteEventFromState = id => {
        const updated = this.state.events.filter(event => event.id !== id);
        this.setState({ events: updated })
    }

    render() {
        const isLoggedIn = this.state.isLoggedIn || false;
        const listType = this.state.listType || '';
        const year = this.state.year;

        return <Container style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Row>
                <Col>
                    <h3>{year} Conference Schedule/Results</h3>
                </Col>
                <Col>
                    <div style={{ float:'right' }}>
                        <Select
                            onChange={this.handleTeamChange}
                            isClearable={true}
                            className="basic-single"
                            classNamePrefix="select"
                            name="teamName"
                            options={options}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ConferenceTable
                        events={this.state.events}
                        updateState={this.updateState}
                        listType={listType}
                        deleteEventFromState={this.deleteEventFromState} />
                </Col>
            </Row>

            {isLoggedIn &&
                <Row>
                    <Col>
                        <ConferenceModal isNew={true} listType={listType} addToState={this.addEvent} />
                    </Col>
                </Row>
            }
        </Container>;
    }
}
